import React from "react";
import {Button} from "react-bootstrap";
import '../css/buttons.css';
import axiosClient from "../AxiosConfig";


interface InstallButtonProps {
    oAuthProvider: 'hubspot' | 'mollie';
    text?: string;
}

/**
 * Make an install button for HubSpot
 * OnClick, fetch the install URL from the server and redirect the user to it
 */
function InstallButton(props: InstallButtonProps) {

    const handleOnClick = async () => {
        console.log('InstallButton.handleOnClick');
        try {
            const response = await axiosClient.get(`/oauth/${props.oAuthProvider}/install`);
            console.log('InstallButton.handleOnClick response', response);
            redirectUser(response.data.url);
        } catch (e) {
            console.log('InstallButton.handleOnClick error', e);
            alertUser('Error getting install URL');
        }
    }

    const redirectUser = (url: string) => {
        if (url) {
            window.location.href = url;
        } else {
            alertUser('No URL provided');
        }
    }

    const alertUser = (message: string) => {
        alert(message);
    }

    /**
     * Return the oAuth provider name
     */
    const getProvider = () => {
        switch (props.oAuthProvider) {
            case 'hubspot':
                return 'HubSpot';
            case 'mollie':
                return 'Mollie';
            default:
                return '';
        }
    }

    return (
        <Button variant="primary" onClick={handleOnClick}>{props.text ? props.text : `Connect ${getProvider()}`}</Button>
    )
}

export default InstallButton;
export class JwtUtil {

    static isJwtExpired(jwtToken: string) {
        if (!jwtToken) {
            return true;
        }

        // Decode JWT without verification
        const base64Url = jwtToken.split('.')[1];
        const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
        const payload = JSON.parse(decodeURIComponent(atob(base64).split('').map(function(c) {
            return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        }).join('')));

        // Get the current time
        const now = Date.now().valueOf() / 1000;

        // Check expiry
        return typeof payload.exp !== 'undefined' && payload.exp < now;

         // Token is still valid
    }

    public static checkJWT() {
        const token = localStorage.getItem('jwt')
        return token && !this.isJwtExpired(token);
    }

    public static removeJWT() {
        localStorage.removeItem('jwt');
    }

    static saveJwt(jwt: string) {
        if (!jwt) {
            return;
        }
        localStorage.setItem('jwt', jwt);
    }

    static get() {
        if (!this.checkJWT()) {
            return null;
        }
        return localStorage.getItem('jwt');
    }

    static getPortalId() {
        const jwtToken = this.get();
        if (!jwtToken) {
            return null;
        }

        try {
            // Decode JWT without verification
            const base64Url = jwtToken.split('.')[1];
            const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
            const payload = JSON.parse(decodeURIComponent(atob(base64).split('').map(function(c) {
                return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
            }).join('')));

            // Return the portal ID if it exists
            return payload.portalId || null;
        } catch (error) {
            console.error('Error decoding JWT:', error);
            return null;
        }
    }
}